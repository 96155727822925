//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchPropertyMemberMonthList, delPropertyMemberMonth } from "@/api/charge";
import Pagination from "@/components/Pagination";
import { getToken } from "@/utils/cache";
import { downloadFun } from "@/utils/util"; // import {} from 'iview-area'

export default {
  name: "propertyMember",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        keyword: ""
      },
      property_name: '',
      property_member: '',
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      villageDialog: false,
      dialogForm: {
        pm_id: "",
        mobile: "",
        valid_time: "",
        remark: ""
      },
      textMap: {
        update: "小区用户续卡",
        create: "添加小区用户"
      },
      villageLoading: false,
      villageOptions: [],
      submitLoading: false,
      rules: {}
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      this.pm_id = this.$route.query.pm_id;
      var data = this.searchForm;
      data.pm_id = this.pm_id;
      fetchPropertyMemberMonthList(data).then(function (response) {
        console.log(response);
        _this.property_member = response.meta.property_member;
        _this.property_name = response.meta.property_name;
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      console.log("搜索");
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleDel: function handleDel(row) {
      var _this2 = this;

      console.log("删除月卡记录");
      var content = "\u60A8\u786E\u8BA4\u8981\u5220\u9664\u8BE5\u8BB0\u5F55\u5417?";
      var title = "删除月卡记录";
      this.$confirm(content, title, {
        confirmButtonText: "提交",
        cancelButtonText: "取消"
      }).then(function () {
        var id = row.id;
        delPropertyMemberMonth(id).then(function (res) {
          console.log(res);

          _this2.$message({
            type: "success",
            message: "删除月卡记录成功"
          });

          var index = _this2.dataList.indexOf(row);

          _this2.dataList.splice(index, 1);
        }).catch(function (err) {
          _this2.$message({
            type: "error",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    },
    handleTime: function handleTime(val) {
      if (val) {
        this.dialogForm["valid_time"][0] = this.$moment(val[0]).format('YYYY-MM-DD');
        this.dialogForm["valid_time"][1] = this.$moment(val[1]).format('YYYY-MM-DD');
      } else {
        this.dialogForm["valid_time"][0] = "";
        this.dialogForm["valid_time"][1] = "";
      }
    }
  }
};